import request from './request'

export function phonePriceList(params){
    return request({
        method:'POST',
        url:'/Passageway/phonePriceList',
        data:params,
    })
}
export function phonePriceHandle(params){
    return request({
        method:'POST',
        url:'/Passageway/phonePriceHandle',
        data:params,
    })
}
export function addPhoneModel2(params){
    return request({
        method:'POST',
        url:'/Passageway/addPhoneModel2',
        data:params,
    })
}
export function selectRamColour(params){
    return request({
        method:'POST',
        url:'/Passageway/selectRamColour',
        data:params,
    })
}

export function addPhoneColour(params){
    return request({
        method:'POST',
        url:'/Passageway/addPhoneColour',
        data:params,
    })
}
export function addPhoneRam2(params){
    return request({
        method:'POST',
        url:'/Passageway/addPhoneRam2',
        data:params,
    })
}
export function delPhoneRam(params){
    return request({
        method:'POST',
        url:'/Passageway/delPhoneRam',
        data:params,
    })
}
export function delPhoneColour(params){
    return request({
        method:'POST',
        url:'/Passageway/delPhoneColour',
        data:params,
    })
}
export function imageUp(params){
    return request({
        method:'POST',
        url:'/Uploadify/imageUp',
        data:params
    })
}

export function addEditPhonePrice(params){
    return request({
        method:'POST',
        url:'/Passageway/addEditPhonePrice',
        data:params
    })
}
export function passagewayList(params){
    return request({
        method:'POST',
        url:'/Passageway/passagewayList',
        data:params
    })
}
export function storeList(params){
    return request({
        method:'POST',
        url:'/Store/storeList',
        data:params
    })
}

export function selectPass(params){
    return request({
        method:'POST',
        url:'/Passageway/selectPass',
        data:params
    })
}

export function selectStore(params){
    return request({
        method:'POST',
        url:'/Passageway/selectStore',
        data:params
    })
}
export function orderList(params){
    return request({
        method:'POST',
        url:'/Passageway/orderList',
        data:params
    })
}

export function orderRemarks(params){
    return request({
        method:'POST',
        url:'/Passageway/orderRemarks',
        data:params
    })
}
export function addEditStore(params){
    return request({
        method:'POST',
        url:'/Store/addEditStore',
        data:params
    })
}
export function getRegion(params){
    return request({
        method:'POST',
        url:'/index/getRegion',
        data:params
    })
}
export function storeInfo(params){
    return request({
        method:'POST',
        url:'/Store/storeInfo',
        data:params
    })
}

export function storeHandle(params){
    return request({
        method:'POST',
        url:'/Store/storeHandle',
        data:params
    })
}
export function importStore(params){
    return request({
        method:'POST',
        url:'/Store/importStore',
        data:params
    })
}
export function downloadStore(params){
    return request({
        method:'POST',
        url:'/Store/downloadStore',
        data:params
    })
}

export function passagewayHandle(params){
    return request({
        method:'POST',
        url:'/Passageway/passagewayHandle',
        data:params
    })
}
export function passagewayInfo(params){
    return request({
        method:'POST',
        url:'/Passageway/passagewayInfo',
        data:params
    })
}
export function addEditPassageway(params){
    return request({
        method:'POST',
        url:'/Passageway/addEditPassageway',
        data:params
    })
}
export function orderHandle(params){
    return request({
        method:'POST',
        url:'/Passageway/orderHandle',
        data:params
    })
}
export function orderDel(params){
    return request({
        method:'POST',
        url:'/Passageway/orderDel',
        data:params
    })
}
export function orderCostPrice(params){
    return request({
        method:'POST',
        url:'/Passageway/orderCostPrice',
        data:params
    })
}

export function orderScrapPrice(params){
    return request({
        method:'POST',
        url:'/Passageway/orderScrapPrice',
        data:params
    })
}
export function completeOrder(params){
    return request({
        method:'POST',
        url:'/Passageway/completeOrder',
        data:params
    })
}
export function partnerList(params){
    return request({
        method:'POST',
        url:'/Partner/partnerList',
        data:params
    })
}
export function selectPartner(params){
    return request({
        method:'POST',
        url:'Passageway/selectPartner',
        data:params
    })
}

export function passagewayPhone(params){
    return request({
        method:'POST',
        url:'/Passageway/passagewayPhone',
        data:params
    })
}
export function editPassagewayPhone(params){
    return request({
        method:'POST',
        url:'/Passageway/editPassagewayPhone',
        data:params
    })
}
export function selectAgent(params){
    return request({
        method:'POST',
        url:'/Passageway/selectAgent',
        data:params
    })
}

export function partnerList2(params){
    return request({
        method:'POST',
        url:'/Partner/partnerList',
        data:params
    })
}

export function partnerHandle(params){
    return request({
        method:'POST',
        url:'/Partner/partnerHandle',
        data:params
    })
}

export function partnerInfo(params){
    return request({
        method:'POST',
        url:'/Partner/partnerInfo',
        data:params
    })
}
export function addEditPartner(params){
    return request({
        method:'POST',
        url:'/Partner/addEditPartner',
        data:params
    })
}
export function agentList(params){
    return request({
        method:'POST',
        url:'/Partner/agentList',
        data:params
    })
}
export function agentHandle(params){
    return request({
        method:'POST',
        url:'/Partner/agentHandle',
        data:params
    })
}
export function agentInfo(params){
    return request({
        method:'POST',
        url:'/Partner/agentInfo',
        data:params
    })
}
export function addEditAgent(params){
    return request({
        method:'POST',
        url:'/Partner/addEditAgent',
        data:params
    })
}

